<template>
  <div class="competition-form">
    <h3>{{ editingCompetition ? t('general.edit') : t('general.create') }} {{ t('competition.competition') }}</h3>
    <form @submit.prevent="$emit('save')">
      <!-- Basic Information Section -->
      <div class="form-section">
        <h4>{{ t('competition.basicInformation') }}</h4>
        <div class="form-group">
          <label for="competition-name">{{ t('competition.competitionName') }}</label>
          <input
            id="competition-name"
            v-model="competition.dashboardCompetitionName"
            type="text"
            :placeholder="t('competition.competitionName')"
            required
          />
        </div>
        <div class="form-group">
          <label for="competition-name">Header {{ t('general.image') }}</label>
          <input
            id="competition-name"
            v-model="competition.name"
            type="text"
            :placeholder="t('general.imageUrl')"
            @input="checkImageUrl"
          />
        </div>
        <div>
          <img
            v-if="isImageUrl"
            :src="competition.name"
            alt="Competition Logo"
            class="competition-logo-preview"
          />
        </div>

        <div class="form-group">
          <label for="competition-footer">{{ t('general.footer') }}</label>
          <input
            id="competition-footer"
            v-model="competition.footer"
            type="text"
            :placeholder="t('general.footer')"
            @input="checkFooterImageUrl"
          />
        </div>
        <div>
          <img
            v-if="isFooterImageUrl"
            :src="competition.footer"
            alt="Footer Image"
            class="footer-image-preview"
          />
        </div>
      </div>

      <!-- Configuration Section -->
      <div class="form-section">
        <h4>{{ t('competition.configuration') }}</h4>
        <div class="form-group">
          <label for="xml-url">XML URL</label>
          <input
            id="xml-url"
            v-model="competition.xmlUrl"
            type="url"
            placeholder="XML URL"
            required
            @blur="$emit('fetch-tags')"
          />
        </div>
        <div class="form-group">
          <label for="refresh-interval"
            >{{ t('competition.refreshInterval') }}</label
          >
          <input
            id="refresh-interval"
            v-model.number="competition.refreshInterval"
            type="number"
            :placeholder="t('competition.refreshInterval')"
            required
            min="5"
          />
        </div>
        <div class="form-group">
          <label for="show-nation-flags">{{ t('competition.showNationFlags') }}</label>
          <select id="show-nation-flags" v-model="competition.showNationFlags">
            <option value="yes">{{ t('general.yes') }}</option>
            <option value="no">{{ t('general.no') }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="show-combined-view">{{ t('competition.showCombinedView') }}</label>
          <select
            id="show-combined-view"
            v-model="competition.showCombinedView"
          >
            <option value="yes">{{ t('general.yes') }}</option>
            <option value="no">{{ t('general.no') }}</option>
          </select>
        </div>
      </div>

      <!-- Headers Section -->
      <div class="form-section">
        <h4>{{ t('competition.headers') }}</h4>
        <div
          v-for="(header, index) in competition.headers"
          :key="index"
          class="header-item"
        >
          <button
            type="button"
            @click="moveHeader(index, -1)"
            :disabled="index === 0"
            class="move-btn"
            title="Move Up"
          >
            ↑
          </button>
          <button
            type="button"
            @click="moveHeader(index, 1)"
            :disabled="index === competition.headers.length - 1"
            class="move-btn"
            title="Move Down"
          >
            ↓
          </button>
          <select v-model="competition.headers[index]" class="header-select">
            <option value="">{{ t('competition.selectAField') }}</option>
            <option v-for="field in availableTags" :key="field" :value="field">
              {{ field }}
            </option>
          </select>
          <input
            v-model="competition.translations[header]"
            type="text"
            :placeholder="`${t('competition.translationFor')} ${header}`"
            class="translation-input"
          />
          <select
            v-model="competition.headerAlignments[header]"
            class="alignment-select"
          >
            <option value="left">{{ t('general.left') }}</option>
            <option value="center">{{ t('general.center') }}</option>
            <option value="right">{{ t('general.right') }}</option>
          </select>
          <button
            type="button"
            @click="removeHeader(index)"
            class="remove-btn"
            title="Remove Header"
          >
            ✕
          </button>
        </div>
        <button type="button" @click="addHeader" class="add-btn">
          + {{ t('button.addHeader') }}
        </button>
      </div>

      <!-- Combined View Headers Section -->
      <div v-if="competition.showCombinedView === 'yes'" class="form-section">
        <h4>{{ t('competition.combinedViewHeaders') }}</h4>
        <div
          v-for="(header, index) in competition.combinedHeaders"
          :key="'combined-' + index"
          class="header-item"
        >
          <button
            type="button"
            @click="moveCombinedHeader(index, -1)"
            :disabled="index === 0"
            class="move-btn"
            title="Move Up"
          >
            ↑
          </button>
          <button
            type="button"
            @click="moveCombinedHeader(index, 1)"
            :disabled="index === competition.combinedHeaders.length - 1"
            class="move-btn"
            title="Move Down"
          >
            ↓
          </button>
          <select
            v-model="competition.combinedHeaders[index]"
            class="header-select"
          >
            <option value="">{{ t('competition.selectAField') }}</option>
            <option
              v-for="field in combinedHeaderOptions"
              :key="field"
              :value="field"
            >
              {{ field }}
            </option>
          </select>
          <input
            v-model="competition.combinedTranslations[header]"
            type="text"
            :placeholder="`${t('competition.translationFor')} ${header}`"
            class="translation-input"
          />
          <select
            v-model="competition.combinedHeaderAlignments[header]"
            class="alignment-select"
          >
            <option value="left">{{ t('general.left') }}</option>
            <option value="center">{{ t('general.center') }}</option>
            <option value="right">{{ t('general.right') }}</option>
          </select>
          <button
            type="button"
            @click="removeCombinedHeader(index)"
            class="remove-btn"
            title="Remove Header"
          >
            ✕
          </button>
        </div>
        <button type="button" @click="addCombinedHeader" class="add-btn">
          + {{ t('button.addCombinedHeader') }}
        </button>
      </div>
      <div class="form-section"></div>
    </form>

    <!-- Floating buttons -->
    <div class="floating-buttons">
      <button @click="$emit('save')" class="btn btn-primary">{{ t('button.saveCompetition') }}</button>
      <button @click="$emit('cancel')" class="btn btn-cancel">{{ t('button.cancel') }}</button>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    competition: Object,
    editingCompetition: Boolean,
    availableTags: Array,
  },
  emits: ['save', 'cancel', 'fetch-tags'],

  setup(props) {
    const isImageUrl = ref(false)
    const isFooterImageUrl = ref(false)
    const { t } = useI18n()
    const combinedHeaderOptions = computed(() => {
      return ['Overall Rank', 'Time Loss', ...props.availableTags]
    })

    const checkImageUrl = () => {
      const url = props.competition.name
      isImageUrl.value = url.match(/\.(jpeg|jpg|gif|png)$/) != null
    }

    const checkFooterImageUrl = () => {
      const url = props.competition.footer
      isFooterImageUrl.value = url.match(/\.(jpeg|jpg|gif|png)$/) != null
    }

    const addHeader = () => {
      props.competition.headers.push('')
    }

    const removeHeader = (index) => {
      props.competition.headers.splice(index, 1)
    }

    const moveHeader = (index, direction) => {
      const newIndex = index + direction
      if (newIndex >= 0 && newIndex < props.competition.headers.length) {
        const headers = props.competition.headers
        const temp = headers[index]
        headers[index] = headers[newIndex]
        headers[newIndex] = temp
      }
    }

    const addCombinedHeader = () => {
      props.competition.combinedHeaders.push('')
    }

    const removeCombinedHeader = (index) => {
      props.competition.combinedHeaders.splice(index, 1)
    }

    const moveCombinedHeader = (index, direction) => {
      const newIndex = index + direction
      if (
        newIndex >= 0 &&
        newIndex < props.competition.combinedHeaders.length
      ) {
        const headers = props.competition.combinedHeaders
        const temp = headers[index]
        headers[index] = headers[newIndex]
        headers[newIndex] = temp
      }
    }

    watch(() => props.competition.name, checkImageUrl)
    watch(() => props.competition.footer, checkFooterImageUrl)

    return {
      isImageUrl,
      isFooterImageUrl,
      checkImageUrl,
      checkFooterImageUrl,
      addHeader,
      removeHeader,
      moveHeader,
      addCombinedHeader,
      removeCombinedHeader,
      moveCombinedHeader,
      combinedHeaderOptions,
      t,
    }
  },
  mounted() {
    this.checkImageUrl()
    this.checkFooterImageUrl()
  },
}
</script>

<style scoped>
/* Competition Form Styling */
.competition-form {
  width: 95%;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.competition-form h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #2c3e50;
}

/* Form Sections */
.form-section {
  margin-bottom: 1.5rem;
}

.form-section h4 {
  margin-bottom: 0.75rem;
  font-size: 1.2rem;
  color: #34495e;
  border-bottom: 1px solid #ecf0f1;
  padding-bottom: 0.3rem;
}

/* Form Groups */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-group label {
  margin-bottom: 0.3rem;
  font-weight: 600;
  color: #2c3e50;
}

.form-group input,
.form-group select {
  padding: 0.5rem 0.75rem;
  font-size: 0.95rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #3498db;
  outline: none;
}

/* Header Items */
.header-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  flex-wrap: wrap;
}

.header-item button {
  margin-right: 0.3rem;
}

.header-select {
  flex: 1;
  margin-right: 0.5rem;
  min-width: 150px;
}

.translation-input {
  flex: 3;
  margin-right: 0.5rem;
}

/* Buttons */
.move-btn,
.remove-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  color: #8d7f7f;
  transition: color 0.2s ease;
}

.remove-btn {
  color: salmon;
  padding-left: 0.5rem;
}

.move-btn:hover,
.remove-btn:hover {
  color: #2c3e50;
}

.add-btn {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 0.4rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  margin-top: 0.5rem;
}

.add-btn:hover {
  background-color: #2980b9;
}

/* Form Inputs Image Previews */
.competition-logo-preview,
.footer-image-preview {
  max-width: 100px;
  margin-top: 0.5rem;
  border-radius: 4px;
  object-fit: contain;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .competition-form {
    padding: 1rem;
  }

  .form-section h4 {
    font-size: 1.1rem;
  }

  .btn {
    font-size: 0.8rem;
    padding: 0.4rem 0.6rem;
  }
}

@media (max-width: 768px) {
  .form-group {
    flex-direction: column;
  }

  .form-group label {
    margin-bottom: 0.3rem;
  }

  .header-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-select,
  .translation-input {
    margin-right: 0;
    width: 100%;
  }

  .btn {
    width: 100%;
    justify-content: center;
  }
}

/* Floating buttons styles */
.floating-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
  background: linear-gradient(135deg, rgba(255,255,255,0.1), rgba(255,255,255,0.2));
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  padding: 10px;
}

.floating-buttons .btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.floating-buttons .btn-primary {
  background-color: rgba(39, 174, 96, 0.9);
  color: #fff;
  box-shadow: 0 4px 6px rgba(39, 174, 96, 0.3);
}

.floating-buttons .btn-primary:hover {
  background-color: rgba(46, 204, 113, 0.9);
  box-shadow: 0 6px 8px rgba(39, 174, 96, 0.4);
}

.floating-buttons .btn-cancel {
  background-color: rgba(231, 76, 60, 0.9);
  color: #fff;
  box-shadow: 0 4px 6px rgba(231, 76, 60, 0.3);
}

.floating-buttons .btn-cancel:hover {
  background-color: rgba(192, 57, 43, 0.9);
  box-shadow: 0 6px 8px rgba(231, 76, 60, 0.4);
}

/* Adjust the main form container to account for the floating buttons */
.competition-form {
  padding-bottom: 80px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .floating-buttons {
    flex-direction: column;
    right: 10px;
    bottom: 10px;
  }

  .floating-buttons .btn {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}
</style>