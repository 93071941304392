<template>
  <div class="embed-code">
    <h3>
      {{ t('competition.embedCodeFor') }}
      {{ competition.dashboardCompetitionName }}
    </h3>
    <pre><code>{{ embedCode }}</code></pre>
    <button
      @click="$emit('copy-embed-code', embedCode)"
      class="btn copy-embed-btn"
    >
      {{ $t('button.copyCode') }}
    </button>
    <button @click="$emit('close')" class="btn close-btn"></button>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    competition: Object,
  },
  setup(props) {
    const { t } = useI18n()

    const embedCode = computed(() => {
      return `<script src="${process.env.VUE_APP_BASE_URL}/sport-results.js"><\/script>
<script>
  initSportResults('${props.competition.id}', '${props.competition.organizerId}');
<\/script>`
    })

    return { embedCode, t }
  },
  emits: ['copy-embed-code', 'close'],
}
</script>

<style scoped>
.embed-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px 40px;
  position: relative;
  border-radius: 4px;
}

.embed-code h3 {
  color: #333;

  text-wrap: wrap;
}

.embed-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}


</style>
