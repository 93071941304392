<template>
  <div class="registration-forms-list">
    <div class="registrations-header">
      <h3>{{ t('registration.yourRegistrations') }}</h3>
      <button
        @click="$emit('create-registration-form')"
        class="btn-primary btn"
      >
        {{ t('button.createNewForm') }}
      </button>
    </div>
    <table>
      <thead>
        <tr>
          <th>{{ t('general.id') }}</th>
          <th>{{ t('general.name') }}</th>
          <th></th>
          <th style="text-align: right">{{ t('general.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="form in registrationForms" :key="form.id">
          <td>{{ form.id }}</td>
          <td>{{ form.name }}</td>
          <td class="embed-column">
            <button
              @click="$emit('show-submissions', form.id)"
              class="submissions-btn btn"
            >
              {{ t('button.showSubmissions') }}
            </button>
            <button @click="$emit('show-embed', form)" class="btn-secondary btn">
              {{ t('button.embedCode') }}
            </button>
          </td>

          <td>
            <div class="actions-container">
              <button @click="$emit('edit', form)" class="btn-primary btn">
                {{ t('button.edit') }}
              </button>
              <button @click="$emit('copy', form)" class="btn-secondary btn">
                {{ t('button.copy') }}
              </button>
              <button @click="$emit('delete', form.id)" class="btn-danger btn">
                {{ t('button.delete') }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()
    return { t }
  },
  props: {
    registrationForms: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'create-registration-form',
    'show-embed',
    'edit',
    'delete',
    'show-submissions',
    'copy',
  ],
}
</script>

<style scoped>
.registration-forms-list {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-bottom: 2rem;
  background-color: #f0f2f5;
  box-sizing: border-box;
  color: #333;
  width: 100%;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.registration-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

h3 {
  margin: 0;
  padding: 0;
}

.table-responsive {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f8f9fa;
}

.registrations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
}

.btn {
  padding: 4px 8px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.btn-primary {
  background-color: #007bff;
  color: white;
}


.btn-danger {
  background-color: #dc3545;
  color: white;
}

.no-forms {
  text-align: center;
  color: #6c757d;
}

.embed-column {
  width: 1%;
  white-space: nowrap;
}

.submissions-btn {
  background-color: #28a745;
  color: white;
}

.btn-secondary {
  background-color: #17a2b8;
  color: white;
}

@media (max-width: 768px) {
  .registration-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .create-btn {
    margin-top: 1rem;
    width: 100%;
  }

  .actions-container {
    flex-direction: column;
  }

  .btn {
    margin-bottom: 0.5rem;
    width: 100%;
  }
}
</style>
