<template>
  <div class="submission-edit">
    <h2>{{ t('registration.editSubmission') }}</h2>
    <div v-if="loading">Loading...</div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <form @submit.prevent="saveSubmission">
        <div v-for="field in formFields" :key="field.id" class="form-field">
          <label :for="field.name">{{ field.name }}</label>
          <input 
            v-if="!isCheckbox(field)"
            :id="field.name"
            v-model="editedSubmission.submission_data[field.name]"
            :type="getInputType(field)"
          >
          <input 
            v-else
            :id="field.name"
            type="checkbox"
            v-model="editedSubmission.submission_data[field.name]"
            true-value="on"
            false-value=""
          >
        </div>
        <div class="form-actions">
          <button type="submit" class="save-button">{{ t('button.save') }}</button>
          <button @click.prevent="confirmCancel" class="cancel-button">{{ t('button.cancel') }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'


export default {
  props: {
    submissionId: {
      type: Number,
      required: true
    },
    formId: {
      type: Number,
      required: true
    }
  },
  emits: ['close', 'update'],
  setup(props, { emit }) {
    const editedSubmission = ref(null)
    const formFields = ref([])
    const loading = ref(true)
    const error = ref(null)
    const { t } = useI18n()
    const loadSubmissionDetails = async () => {
      try {
        const [submissionResponse, formResponse] = await Promise.all([
          axios.get(`/registration/${props.formId}/submissions/${props.submissionId}`),
          axios.get(`/registration/${props.formId}`)
        ])
        editedSubmission.value = submissionResponse.data
        formFields.value = formResponse.data.groups.flatMap(group => group.fields)
        loading.value = false
      } catch (err) {
        error.value = 'Failed to load submission details'
        loading.value = false
      }
    }

    const isCheckbox = (field) => {
      return field.type === 'checkbox'
    }

    const getInputType = (field) => {
      switch (field.type) {
        case 'number':
          return 'number'
        case 'date':
          return 'date'
        default:
          return 'text'
      }
    }

    const saveSubmission = async () => {
      try {
        const response = await axios.put(`/registration/${props.formId}/submissions/${props.submissionId}`, {
          submission_data: editedSubmission.value.submission_data
        })
        emit('update', response.data)
        // emit('close')
      } catch (err) {
        error.value = 'Failed to save submission'
      }
    }

    const confirmCancel = () => {
      if (confirm(t('general.cancelConfirm'))) {
        emit('close')
      }
    }

    onMounted(loadSubmissionDetails)

    return {
      editedSubmission,
      formFields,
      loading,
      error,
      isCheckbox,
      getInputType,
      saveSubmission,
      confirmCancel,
      t
    }
  }
}
</script>

<style scoped>
.submission-edit {
  padding: 20px;
}

.form-field {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
input[type="date"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-actions {
  margin-top: 20px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  margin-right: 10px;
}

.save-button {
  background-color: #4CAF50;
  color: white;
}

.save-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover {
  background-color: #d32f2f;
}
</style>