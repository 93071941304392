import { createStore } from 'vuex'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

export default createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('token') || null,
    error: null,
    logoutTimer: null,
    tokenRefreshInterval: null,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    },
    setError(state, error) {
      state.error = error
    },
    clearError(state) {
      state.error = null
    },
    logout(state) {
      state.user = null
      state.token = null
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      delete axios.defaults.headers.common['Authorization']
      if (state.logoutTimer) {
        clearTimeout(state.logoutTimer)
      }
      if (state.tokenRefreshInterval) {
        clearInterval(state.tokenRefreshInterval)
      }
    },
    setLogoutTimer(state, timer) {
      state.logoutTimer = timer
    },
    setTokenRefreshInterval(state, interval) {
      state.tokenRefreshInterval = interval
    },
  },
  actions: {
    async login({ commit, dispatch }, { email, password }) {
      commit('clearError')
      try {
        const response = await axios.post('/auth/login', { email, password })
        const { user, token } = response.data
        commit('setUser', user)
        commit('setToken', token)
        dispatch('setAutoLogout')
      } catch (error) {
        let errorMessage = 'An unexpected error occurred'
        if (error.response) {
          errorMessage =
            error.response.data.message ||
            `Server error: ${error.response.status}`
        } else if (error.request) {
          errorMessage =
            'No response received from server. Please check your network connection and try again.'
          console.error('Error request:', error.request)
        } else {
          errorMessage = error.message
        }
        commit('setError', errorMessage)
        throw error
      }
    },
    logout({ commit, state }) {
      if (state.logoutTimer) {
        clearTimeout(state.logoutTimer)
      }
      commit('logout')
      window.location.reload()
    },
    initializeAuth({ commit, dispatch }) {
      const token = localStorage.getItem('token')
      const user = JSON.parse(localStorage.getItem('user'))
      if (token && user) {
        try {
          const decoded = jwtDecode(token)
          const currentTime = Date.now() / 1000
          if (decoded.exp && decoded.exp > currentTime) {
            commit('setToken', token)
            commit('setUser', user)
            dispatch('setAutoLogout', decoded.exp - currentTime)
          } else {
            dispatch('logout')
          }
        } catch (e) {
          console.error('Invalid token:', e)
          dispatch('logout')
        }
      }
    },
    setAutoLogout({ commit, dispatch, state }) {
      if (state.logoutTimer) {
        clearTimeout(state.logoutTimer)
      }

      const token = state.token
      if (!token) return

      const decodedToken = jwtDecode(token)
      const expirationTime = decodedToken.exp * 1000 // Convert to milliseconds
      const currentTime = Date.now()
      const timeUntilExpiration = expirationTime - currentTime

      if (timeUntilExpiration <= 0) {
        dispatch('logout')
        return
      }

      const timer = setTimeout(() => {
        dispatch('logout')
      }, timeUntilExpiration)

      commit('setLogoutTimer', timer)
    },
    async refreshToken({ commit, dispatch }) {
      try {
        const response = await axios.post('/auth/refresh-token')
        const { token } = response.data
        commit('setToken', token)
        dispatch('setAutoLogout')
        return token
      } catch (error) {
        console.error('Token refresh failed:', error)
        dispatch('logout')
      }
    },
    resetLogoutTimer({ dispatch, state }) {
      const token = state.token
      if (!token) return

      const decodedToken = jwtDecode(token)
      const expirationTime = decodedToken.exp * 1000 // Convert to milliseconds
      const currentTime = Date.now()
      const timeUntilExpiration = expirationTime - currentTime

      // Refresh token if less than 30 minutes left
      if (timeUntilExpiration <= 30 * 60 * 1000) {
        dispatch('refreshToken').then((newToken) => {
          if (newToken) {
            dispatch('setAutoLogout')
          }
        })
      } else {
        // If more than 15 minutes left, just reset the auto logout timer
        dispatch('setAutoLogout')
      }
    },
  },
  getters: {
    isAuthenticated: (state) => {
      if (!state.token) return false
      try {
        const decoded = jwtDecode(state.token)
        const currentTime = Date.now() / 1000
        return decoded.exp && decoded.exp > currentTime
      } catch (e) {
        console.error('Invalid token:', e)
        return false
      }
    },
    userRole: (state) => (state.user ? state.user.role : null),
    getError: (state) => state.error,
  },
})
