<template>
  <div class="language-switcher">
    <select v-model="currentLanguage" @change="changeLanguage">
      <option v-for="lang in availableLanguages" :key="lang.code" :value="lang.code">
        {{ lang.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LanguageSwitcher',
  setup() {
    const { locale, availableLocales } = useI18n()
    const currentLanguage = ref(locale.value)

    const availableLanguages = [
      { code: 'en', name: 'English' },
      { code: 'fr', name: 'Français' },
      { code: 'es', name: 'Español' },
      { code: 'ee', name: 'Eesti' },
      // Add more languages as needed
    ].filter(lang => availableLocales.includes(lang.code))

    const changeLanguage = () => {
      locale.value = currentLanguage.value
      localStorage.setItem('userLanguage', currentLanguage.value)
    }

    onMounted(() => {
      const savedLanguage = localStorage.getItem('userLanguage')
      if (savedLanguage && availableLocales.includes(savedLanguage)) {
        currentLanguage.value = savedLanguage
        locale.value = savedLanguage
      }
    })

    return {
      currentLanguage,
      availableLanguages,
      changeLanguage
    }
  }
}
</script>

<style scoped>
.language-switcher {
  display: inline-block;
}

select {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
}
</style>
