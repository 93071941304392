<template>
  <div class="competitions-list">
    <div v-if="competitions.length > 0" class="competitions-list">
      <div class="competitions-header">
        <h3>{{ $t('competition.yourCompetitions') }}</h3>
        <button @click="$emit('create-competition')" class="btn btn-primary">
          {{ $t('button.createCompetition') }}
        </button>
      </div>
      <div class="table-responsive">
        <table class="competitions-table">
          <thead>
            <tr>
              <th>{{ $t('general.id') }}</th>
              <th>{{ $t('general.name') }}</th>
              <th class="embed-column"></th>
              <th style="text-align: right">{{ $t('general.actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="comp in competitions" :key="comp.id">
              <td>{{ comp.id }}</td>
              <td>{{ comp.dashboardCompetitionName }}</td>
              <td class="embed-column">
                <button
                  @click="$emit('show-embed', comp)"
                  class="btn btn-secondary"
                >
                  {{ $t('button.embedCode') }}
                </button>
              </td>
              <td>
                <div class="actions-container">
                  <button @click="$emit('edit', comp)" class="btn btn-primary">
                    {{ $t('button.edit') }}
                  </button>

                  <button
                    @click="$emit('copy', comp)"
                    class="btn btn-secondary"
                  >
                    {{ $t('button.copy') }}
                  </button>

                  <button
                    @click="$emit('delete', comp.id)"
                    class="btn btn-danger"
                  >
                    {{ $t('button.delete') }}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="no-competitions">
      <p>{{ $t('competition.noCompetitions') }}</p>
      <button
        v-if="competitions.length == 0"
        @click="$emit('create-competition')"
        class="btn create-competition-btn"
      >
        {{ $t('competition.createFirstCompetition') }}
      </button>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
  props: {
    competitions: Array,
  },
  emits: ['edit', 'show-embed', 'delete', 'create-competition', 'copy'], // Add 'copy' to emits
}
</script>

<style scoped>
.competitions-list {
  width: 100%;
}

.competitions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

h3 {
  margin: 0;
  padding: 0;
}

.create-competition-btn {
  background-color: #2ecc71;
  color: #fff;
  border: none;
  padding: 0.6rem 1.2rem !important;
  font-weight: bold;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-competition-btn:hover {
  background-color: #27ae60;
}

.competitions-table {
  width: 100%;
  border-collapse: collapse;
}

.competitions-table th,
.competitions-table td {
  border: 1px solid #dee2e6;
  padding: 8px;
  text-align: left;
}

.competitions-table th {
  background-color: #f8f9fa;
}

.actions-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.btn {
  padding: 4px 8px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: white;
}

.btn-secondary {
  background-color: #17a2b8;
  color: white;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
}

.no-competitions {
  text-align: center;
  color: #6c757d;
}

.embed-column {
  width: 1%;
  white-space: nowrap;
}
</style>
