<template>
  <div class="registration-form-embed-code">
    <h2>Embed Code for {{ registrationForm.name }}</h2>
    <pre><code>{{ embedCode }}</code></pre>
    <button @click="copyEmbedCode" class="btn copy-embed-btn">
      Copy Embed Code
    </button>
    <button @click="$emit('close')" class="btn close-btn"></button>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    registrationForm: {
      type: Object,
      required: true,
    },
  },
  emits: ['close', 'copy-embed-code'],
  setup(props, { emit }) {
    const embedCode = computed(() => {
      return `<script src="${process.env.VUE_APP_BASE_URL}/registration-form.js"><\/script>
<script>
  initRegistrationForm('${props.registrationForm.id}');
<\/script>`
    })

    const copyEmbedCode = () => {
      emit('copy-embed-code', embedCode.value)
    }

    return {
      embedCode,
      copyEmbedCode,
    }
  },
}
</script>

<style scoped>
.registration-form-embed-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  padding: 20px 40px;
  position: relative;
  border-radius: 4px;
}
</style>
