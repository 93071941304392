import { ref } from 'vue'

export function useNotification() {
  const showNotificationFlag = ref(false)
  const notificationMessage = ref('')
  const notificationType = ref('success')

  const showNotification = (message, type = 'success') => {
    notificationMessage.value = message
    notificationType.value = type
    showNotificationFlag.value = true
    setTimeout(() => {
      showNotificationFlag.value = false
    }, 3000)
  }

  return {
    showNotificationFlag,
    notificationMessage,
    notificationType,
    showNotification
  }
}
