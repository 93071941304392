<template>
  <div id="app">
    <nav v-if="isAuthenticated">
      <div class="nav-container">
        <div class="nav-placeholder"></div>
        <div class="nav-links">
          <router-link to="/admin" v-if="userRole === 'admin'">
            {{ $t('nav.adminDashboard') }}
          </router-link>
          <router-link to="/organizer" v-if="userRole === 'organizer'">
            {{ $t('nav.results') }}
          </router-link>
          <router-link to="/registrations" v-if="userRole === 'organizer'">
            {{ $t('nav.registrationForms') }}
          </router-link>
          <!-- Remove the following router-link -->
          <!-- <router-link
            to="/registration-submissions"
            v-if="userRole === 'organizer'"
          >
            {{ $t('nav.registrationSubmissions') }}
          </router-link> -->
        </div>
        <div class="logout-container">
          <LanguageSwitcher class="language-switcher" />
          <a class="logout-button" href="#" @click.prevent="handleLogout">
            {{ $t('nav.logout') }}
          </a>
        </div>
      </div>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
import { computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import debounce from 'lodash/debounce'
import { useI18n } from 'vue-i18n'
import LanguageSwitcher from './components/UI/General/LanguageSwitcher.vue'

export default {
  components: {
    LanguageSwitcher,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    const isAuthenticated = computed(() => store.getters.isAuthenticated)
    const userRole = computed(() => store.getters.userRole)

    const handleLogout = () => {
      store.dispatch('logout')
      router.push('/')
    }

    // Update the handleUserAction method
    const handleUserAction = debounce(() => {
      if (isAuthenticated.value) {
        store.dispatch('resetLogoutTimer')
      }
    }, 300)

    // Set up event listeners when the component is mounted
    onMounted(() => {
      document.title = 'Sport Results'
      window.addEventListener('click', handleUserAction)
      window.addEventListener('keypress', handleUserAction)
      window.addEventListener('scroll', handleUserAction)
    })

    // Remove event listeners when the component is unmounted
    onBeforeUnmount(() => {
      window.removeEventListener('click', handleUserAction)
      window.removeEventListener('keypress', handleUserAction)
      window.removeEventListener('scroll', handleUserAction)
    })

    return {
      isAuthenticated,
      userRole,
      handleLogout,
      t,
    }
  },
}
</script>

<style>
/* General styles */
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 15px 20px;
  background: linear-gradient(to right, #2c3e50, #3498db);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-placeholder {
  flex: 1;
}

.nav-links {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-links a {
  color: #ecf0f1;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.3s ease;
  font-weight: 600;
}

.nav-links a:hover {
  color: #ffd700;
  /* transform: translateY(-2px); */
}

.nav-links a.router-link-exact-active {
  color: #2ecc71;
}

.logout-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.logout-button {
  display: inline-block;
  padding: 8px 15px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ecf0f1;
  border: 1px solid #ecf0f1;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-button:active {
  transform: translateY(0);
  box-shadow: none;
}

.language-switcher {
  margin-right: 15px;
}

/* Modal Overlay and Content */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  margin: 1rem;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.copy-embed-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.copy-embed-btn:hover {
  background-color: #218838;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 16px;
  background-color: inherit;
  color: salmon;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}

.close-btn::before {
  content: 'x';
}

pre {
  background-color: #e9ecef;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  overflow-x: auto;
}

code {
  font-family: monospace;
}
</style>
