<template>
  <div class="login-container">
    <div class="login-form">
      <h2>Welcome Back</h2>
      <form @submit.prevent="handleLogin">
        <div class="input-group">
          <input
            type="email"
            id="email"
            v-model="email"
            @blur="validateEmail"
            required
            placeholder="Email"
          />
          <p v-if="emailError" class="error">{{ emailError }}</p>
        </div>
        <div class="input-group">
          <input
            type="password"
            id="password"
            v-model="password"
            required
            placeholder="Password"
          />
          <p v-if="passwordError" class="error">{{ passwordError }}</p>
        </div>
        <button type="submit" >Sign In</button>
      </form>
      <p v-if="error" class="error-message">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const error = ref('')
    const emailError = ref('')
    const passwordError = ref('')
    const router = useRouter()
    const store = useStore()

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!email.value) {
        emailError.value = 'Email is required'
      } else if (!emailRegex.test(email.value)) {
        emailError.value = 'Please enter a valid email address'
      } else {
        emailError.value = ''
      }
    }

    const validatePassword = () => {
      if (!password.value) {
        passwordError.value = 'Password is required'
      } else if (password.value.length < 6) {
        passwordError.value = 'Password must be at least 6 characters long'
      } else {
        passwordError.value = ''
      }
    }

    const isFormValid = computed(() => {
      return (
        email.value &&
        password.value &&
        !emailError.value &&
        !passwordError.value
      )
    })

    const handleLogin = async () => {
      validateEmail()
      validatePassword()
      if (!isFormValid.value) {
        return
      }

      try {
        error.value = '' // Clear previous errors
        await store.dispatch('login', {
          email: email.value,
          password: password.value,
        })
        const userRole = store.state.user.role
        if (userRole === 'admin') {
          router.push('/admin')
        } else if (userRole === 'organizer') {
          router.push('/organizer')
        }
      } catch (err) {
        error.value =
          (err.response && err.response.data && err.response.data.message) ||
          'An error occurred during login'
      }
    }

    return {
      email,
      password,
      error,
      emailError,
      passwordError,
      handleLogin,
      validateEmail,
      validatePassword,
      isFormValid,
    }
  },
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f9fc;
}

.login-form {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
}

h2 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  text-align: center;
}

.input-group {
  margin-bottom: 1rem;
}

input {
  width: 100%;
  padding: 0.6rem;
  border: 1px solid #e1e5eb;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: #3498db;
}

button {
  width: 100%;
  padding: 0.6rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}

button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.error-message,
.error {
  color: #e74c3c;
  font-size: 0.8rem;
  margin-top: 0.25rem;
}
</style>
