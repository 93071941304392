<template>
  <div class="organizer-dashboard">
    <transition name="fade">
      <div
        v-if="showNotificationFlag"
        class="notification"
        :class="notificationType"
      >
        {{ notificationMessage }}
      </div>
    </transition>

    <!-- Competition Form Transition -->
    <CompetitionForm
      v-if="showCompetitionForm"
      :competition="competition"
      :editingCompetition="editingCompetition"
      :availableTags="availableTags"
      @save="saveCompetition"
      @cancel="cancelEdit"
      @fetch-tags="fetchAvailableTags"
    />

    <!-- Competitions List Component -->
    <CompetitionsList
      v-if="!showCompetitionForm"
      :competitions="competitions"
      @edit="editCompetition"
      @show-embed="showEmbedCode"
      @delete="deleteCompetition"
      @create-competition="toggleCompetitionForm"
      @copy="copyCompetition"
    />

    <!-- Embed Code Modal -->
    <transition name="fade">
      <div v-if="showEmbed" class="modal-overlay" @click="closeEmbedModal">
        <div class="modal-content" @click.stop>
          <EmbedCode
            :competition="selectedCompetition"
            @close="closeEmbedModal"
            @copy-embed-code="copyEmbedCode($event)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import CompetitionsList from './UI/Results/CompetitionsList.vue'
import CompetitionForm from './UI/Results/CompetitionForm.vue'
import EmbedCode from './UI/Results/EmbedCode.vue'
import { useNotification } from '@/composables/useNotification'
import { useI18n } from 'vue-i18n'

export default {
  components: {
    CompetitionsList,
    CompetitionForm,
    EmbedCode,
  },
  setup() {
    // Initial competition state
    const getInitialCompetition = () => ({
      dashboardCompetitionName: '',
      name: '',
      xmlUrl: '',
      refreshInterval: 60,
      headers: [],
      translations: {},
      combinedHeaders: [],
      combinedTranslations: {},
      headerAlignments: {},
      combinedHeaderAlignments: {},
      footer: '',
      showCombinedView: 'no',
      showNationFlags: 'no',
    })

    // Reactive state variables
    const competitions = ref([])
    const competition = ref(getInitialCompetition())
    const editingCompetition = ref(false)
    const showEmbed = ref(false)
    const selectedCompetition = ref(null)
    const embedCode = ref('')
    const availableTags = ref([])
    const showCompetitionForm = ref(false)
    const { t } = useI18n()

    const { showNotificationFlag, notificationMessage, notificationType, showNotification } = useNotification()

    // Fetch competitions from API
    const fetchCompetitions = async () => {
      try {
        const response = await axios.get('/organizer/competitions')
        competitions.value = response.data
          .map((comp) => ({
            id: comp.id,
            dashboardCompetitionName: comp.dashboard_competition_name,
            name: comp.name,
            xmlUrl: comp.xml_url,
            refreshInterval: comp.refresh_interval,
            headers: Array.isArray(comp.headers)
              ? comp.headers
              : JSON.parse(comp.headers),
            translations:
              typeof comp.translations === 'object'
                ? comp.translations
                : JSON.parse(comp.translations),
            combinedHeaders: Array.isArray(comp.combined_headers)
              ? comp.combined_headers
              : JSON.parse(comp.combined_headers || '[]'),
            combinedTranslations:
              typeof comp.combined_translations === 'object'
                ? comp.combined_translations
                : JSON.parse(comp.combined_translations || '{}'),
            headerAlignments:
              typeof comp.header_alignments === 'object'
                ? comp.header_alignments
                : JSON.parse(comp.header_alignments || '{}'),
            combinedHeaderAlignments:
              typeof comp.combined_header_alignments === 'object'
                ? comp.combined_header_alignments
                : JSON.parse(comp.combined_header_alignments || '{}'),
            footer: comp.footer || '',
            showCombinedView: comp.showCombinedView || 'no',
            showNationFlags: comp.show_nation_flags || 'no',
            organizerId: comp.organizer_id,
          }))
          .sort((a, b) => b.id - a.id) // Sort based on id
      } catch (error) {
        console.error('Error fetching competitions:', error)
        showNotification(
          'Failed to fetch competitions. Please try again later.',
          'error'
        )
      }
    }

    // Save competition to API
    const saveCompetition = async () => {
      try {
        await axios.post('/competition', competition.value)
        await fetchCompetitions()
        resetForm()
        showCompetitionForm.value = false
        showNotification('Competition saved successfully!', 'success')
      } catch (error) {
        console.error('Error saving competition:', error)
        const errorMsg =
          error.response.data.error || 'Failed to save competition.'
        showNotification(errorMsg, 'error')
      }
    }

    // Delete competition from API
    const deleteCompetition = async (competitionId) => {
      if (confirm(t('competition.deleteCompConfirm'))) {
        try {
          await axios.delete(`/competition/${competitionId}`)
          await fetchCompetitions()
          showNotification('Competition deleted successfully!', 'success')
        } catch (error) {
          console.error('Error deleting competition:', error)
          showNotification(
            'Failed to delete competition. Please try again.',
            'error'
          )
        }
      }
    }

    // Edit competition
    const editCompetition = async (comp) => {
      competition.value = { ...comp }
      editingCompetition.value = true
      showCompetitionForm.value = true
      await fetchAvailableTags()
    }

    // Fetch available tags from API
    const fetchAvailableTags = async () => {
      if (!competition.value.xmlUrl) return
      try {
        const response = await axios.get('/competition/available-tags', {
          params: { xmlUrl: competition.value.xmlUrl },
        })
        availableTags.value = response.data
      } catch (error) {
        console.error('Error fetching available tags:', error)
        alert(
          'Failed to fetch available tags. Please check the XML URL and try again.'
        )
      }
    }

    // Toggle competition form visibility
    const toggleCompetitionForm = () => {
      showCompetitionForm.value = !showCompetitionForm.value
      if (!showCompetitionForm.value) {
        resetForm()
      }
    }

    // Reset competition form to initial state
    const resetForm = () => {
      competition.value = getInitialCompetition()
      editingCompetition.value = false
      availableTags.value = []
    }

    // Cancel editing competition
    const cancelEdit = () => {
      if (confirm(t('general.cancelConfirm'))) {
        resetForm()
        showCompetitionForm.value = false
      }
    }

    // Show embed code modal
    const showEmbedCode = (comp) => {
      selectedCompetition.value = comp
      showEmbed.value = true
    }

    // Close embed code modal
    const closeEmbedModal = () => {
      showEmbed.value = false
    }

    // Copy embed code to clipboard
    const copyEmbedCode = async (embedCodeText) => {
      try {
        await navigator.clipboard.writeText(embedCodeText)
        showNotification('Embed code copied to clipboard!', 'success')
        closeEmbedModal()
      } catch (err) {
        console.error('Error copying embed code:', err)
        showNotification('Failed to copy embed code.', 'error')
      }
    }

    // Copy competition function
    const copyCompetition = (comp) => {
      const copiedComp = JSON.parse(JSON.stringify(comp))
      copiedComp.id = null
      copiedComp.dashboardCompetitionName = `Copy of ${copiedComp.dashboardCompetitionName}`
      copiedComp.name = `Copy of ${copiedComp.name}`
      competition.value = copiedComp
      editingCompetition.value = false
      showCompetitionForm.value = true
      fetchAvailableTags()
    }

    // Initialize by fetching competitions
    onMounted(() => {
      fetchCompetitions()
    })

    return {
      competitions,
      competition,
      editingCompetition,
      showEmbed,
      selectedCompetition,
      embedCode,
      availableTags,
      showCompetitionForm,
      toggleCompetitionForm,
      cancelEdit,
      fetchAvailableTags,
      saveCompetition,
      editCompetition,
      showEmbedCode,
      closeEmbedModal,
      copyEmbedCode,
      deleteCompetition,
      showNotificationFlag,
      notificationMessage,
      notificationType,
      copyCompetition,
      t
    }
  },
}
</script>

<style scoped>
/* -------------------------------------------------------------------
   Organizer Dashboard Overall Styling
------------------------------------------------------------------- */

/* General Dashboard Container */
.organizer-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  padding-bottom: 2rem;
  background-color: #f0f2f5;
  box-sizing: border-box;
  color: #333;
  width: 100%;
  border-radius: 10px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}

/* Notification Styling */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.notification.success {
  background-color: #2ecc71;
}

.notification.error {
  background-color: #e74c3c;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}




</style>
