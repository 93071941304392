import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import AdminView from '@/views/AdminView.vue'
import OrganizerView from '@/views/OrganizerView.vue'
import RegistrationSubmissions from '../components/RegistrationSubmissions.vue'
import Registrations from '../components/Registrations.vue'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/organizer',
    name: 'Organizer',
    component: OrganizerView,
    meta: { requiresAuth: true, requiresOrganizer: true },
  },
  {
    path: '/registrations/registration-submissions/:formId',
    name: 'RegistrationSubmissions',
    component: RegistrationSubmissions,
    meta: { requiresAuth: true, requiresOrganizer: true },
  },
  {
    path: '/registrations',
    name: 'Registrations',
    component: Registrations,
    meta: { requiresAuth: true, requiresOrganizer: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ name: 'Login' })
    } else {
      if (to.matched.some((record) => record.meta.requiresAdmin)) {
        if (store.getters.userRole === 'admin') {
          next()
        } else {
          next({ name: 'Organizer' })
        }
      } else if (to.matched.some((record) => record.meta.requiresOrganizer)) {
        if (store.getters.userRole === 'organizer') {
          next()
        } else {
          next({ name: 'Admin' })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
