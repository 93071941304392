<template>
  <div class="results-view">
    <Results />
  </div>
</template>

<script>
import Results from '@/components/Results.vue'

export default {
  components: {
    Results,
  },
}
</script>
<style scoped>
.results-view {
  margin: 1rem;
}
</style>
