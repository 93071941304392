import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import ee from '@/locales/ee.json'
// Import other languages as needed

const i18n = createI18n({
  locale: 'ee', // set default locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
    ee,
    // Add other languages here
  },
})

export default i18n
